<template>
	<a-config-provider :locale="zh_CN">
		<a-spin :spinning="loading">
			<a-card>
				<a-row :gutter="24" style="margin-bottom: 20px">
					<a-col :span="2">
						<q-label :label="l('出勤日期')"> </q-label>
					</a-col>
					<a-col :span="6">
						<a-range-picker
							:allowClear="false"
							@change="onDateChange"
							v-model="DateTime"
							style="width: 100%"
						/>
					</a-col>

					<a-col :span="3">
						<a-tree-select
							@change="companyChange"
							:placeholder="l('Company')"
							style="width: 100%"
							allow-clear
							tree-default-expand-all
							:dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
							:tree-data="companyTreeList"
							v-model="CompanyId"
						/>
					</a-col>

					<a-col :span="3">
						<a-tree-select
							v-model="DepartmentId"
							style="width: 100%"
							show-search
							:filterTreeNode="searchTree"
							:dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
							:tree-data="DepTreeData"
							:placeholder="l('Department')"
							allow-clear
							@change="SelectDepartmentTree"
						>
						</a-tree-select>
					</a-col>
					<a-col :span="6">
						<q-label>
							<a-input-search
								name="filterText"
								:placeholder="l('SearchWithThreeDot')"
								@search="searchChange"
								enterButton
								v-model="filterText"
							/>
						</q-label>
					</a-col>
					<a-col :span="4" style="line-height:40px;">
						<a-checkbox v-model="emquit" @change="boxChange" style="width: 100%">含离职员工</a-checkbox>
					</a-col>
				</a-row>
				<a-row>
					<a-col :span="6" style="margin-top:-20px">
						<a-button :type="'primary'" @click="exportToExcel()" v-if="isGranted('hit_time_excel')">
							<a-icon type="download" />
							导出报表
						</a-button>
					</a-col>
				</a-row>
				<a-row style="margin-top:10px">
					<a-table
						@change="sorterChange"
						class="list-table"
						:pagination="false"
						:columns="columns"
						:rowKey="(tableDatas) => tableDatas.id"
						:scroll="{ x: 1600, y: scroll_y }"
						:dataSource="tableData"
					>
					</a-table>
					<a-pagination
						class="pagination"
						:total="totalItems"
						v-model="pageNumber"
						showSizeChanger
						showQuickJumper
						:showTotal="showTotalFun"
						@change="onChange"
						@showSizeChange="showSizeChange"
					/>
				</a-row>
			</a-card>
		</a-spin>
	</a-config-provider>
</template>

<script>
import zh_CN from 'ant-design-vue/lib/locale-provider/zh_CN';
import { AppComponentBase } from '../../../shared/component-base';
import moment from 'moment';
import {
	CompanyServiceProxy,
	DepartmentServiceProxy,
	EnterpriseAttendCycleServiceProxy,
	HitTimeServiceProxy,
} from '../../../shared/service-proxies';
import { Dic, fileDownloadService } from '../../../shared/utils';
import { abpService } from '../../../shared/abp';
import DepTreeUntils from '../../organization/department/dep-tree-untils/dep-tree-untils';

export default {
	name: 'entrylog',
	mixins: [AppComponentBase],
	data() {
		return {
			zh_CN,
			//表格上方间隔
			defaultTop: 90,
			//loading:false,
			// 总数
			totalItems: 0,
			// 当前页码
			pageNumber: 1,
			// 共多少页
			totalPages: 1,
			// 条数显示范围
			pagerange: [1, 1],
			filterText: undefined,
			// 显示条数
			pageSizeOptions: ['10', '20', '30', '40', '50'],
			request: { sorting: '', maxResultCount: 10, skipCount: 0 },
			columns: [
				{
					title: this.l('JobNumber'),
					dataIndex: 'jobNumber',
					ellipsis: true,
					sorter: true,
					fixed: 'left',
					align: 'center',
					width: 100,
					scopedSlots: { customRender: 'jobNumber' },
				},
				{
					title: this.l('RealName'),
					dataIndex: 'realName',
					ellipsis: true,
					sorter: true,
					fixed: 'left',
					align: 'center',
					width: 100,
					scopedSlots: { customRender: 'realName' },
				},
				{
					title: this.l('Company'),
					dataIndex: 'fullName',
					ellipsis: true,
					sorter: true,
					align: 'center',
					scopedSlots: { customRender: 'fullName' },
				},
				{
					title: this.l('Department'),
					dataIndex: 'displayName',
					ellipsis: true,
					sorter: true,
					align: 'center',
					scopedSlots: { customRender: 'displayName' },
				},
				{
					title: this.l('出勤日期'),
					dataIndex: 'attDate',
					ellipsis: true,
					sorter: true,
					align: 'center',
					scopedSlots: { customRender: 'attDate' },
				},
				{
					title: this.l('出勤班次'),
					dataIndex: 'shiftName',
					ellipsis: true,
					sorter: true,
					align: 'center',
					scopedSlots: { customRender: 'shiftName' },
				},
				{
					title: this.l('ShouldStartTime'),
					dataIndex: 'shouldStartTime',
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'shouldStartTime' },
				},
				{
					title: this.l('ShouldEndTime'),
					dataIndex: 'shouldEndTime',
					ellipsis: true,
					align: 'center',
					scopedSlots: { customRender: 'shouldEndTime' },
				},
				{
					title: this.l('DutyFrom'),
					dataIndex: 'dutyFrom',
					ellipsis: true,
					align: 'center',
					sorter: true,
					scopedSlots: { customRender: 'dutyFrom' },
					width: 150,
				},
				{
					title: this.l('DutyTo'),
					dataIndex: 'dutyTo',
					ellipsis: true,
					sorter: true,
					align: 'center',
					scopedSlots: { customRender: 'dutyTo' },
					width: 150,
				},
				{
					title: this.l('AbnormalType'),
					dataIndex: 'abnormalType',
					ellipsis: true,
					sorter: true,
					align: 'center',
					scopedSlots: { customRender: 'abnormalType' },
					customRender: (text, row, index) => {
						var message = this.getInfo(text);
						return {
							children: message,
							attrs: {},
						};
					},
				},
				{
					title: this.l('SignCardNums'),
					dataIndex: 'signCardNums',
					ellipsis: true,
					sorter: true,
					align: 'center',
					scopedSlots: { customRender: 'signCardNums' },
				},
			],
			tableData: [],
			emquit: true,
			companyTreeList: [],
			DepTreeData: [],
			CompanyId: undefined,
			DepartmentId: undefined,
			DateTime: [],
			StartTime: null,
			EndTime: null,
			Year: undefined,
			Month: undefined,
			SignCardNumsList: [],
			_fileDownloadService: '',
		};
	},

	created() {
		this._CompanyConfigServiceProxy = new CompanyServiceProxy(this.$apiUrl, this.$api);
		this._DepartmentServiceProxy = new DepartmentServiceProxy(this.$apiUrl, this.$api);
		this._enterpriseAttendCycleServiceProxy = new EnterpriseAttendCycleServiceProxy(this.$apiUrl, this.$api);
		this._HitTimeServiceProxy = new HitTimeServiceProxy(this.$apiUrl, this.$api);
		this._fileDownloadService = fileDownloadService;
		this.Year = moment().year();
		this.Month = moment().month() + 1;
	},

	async mounted() {
		await this.getCycle();
		this.companyTreeInit();
		if (abpService.abp.userInfo.companyId) {
			this.CompanyId = abpService.abp.userInfo.companyId;
		}
		this.getData();
		this.SignCardNumsList = await Dic.getInstance().getDicAsync('QYB_AbnormalType');
	},
	watch: {
		CompanyId: function(value) {
			this.getData();
		},
	},

	methods: {
		searchTree(inputValue, treeNode) {
			return treeNode.data.props.title.includes(inputValue);
		},
		searchChange() {
			this.request.skipCount = 0;
			this.pageNumber = 1;
			this.getData();
		},
		getData() {
			this.loading = true;
			this._HitTimeServiceProxy
				.getPaged(
					this.DepartmentId,
					this.CompanyId,
					this.StartTime ? moment(this.StartTime) : undefined,
					this.EndTime ? moment(this.EndTime) : undefined,
					this.emquit,
					this.filterText,
					this.request.sorting,
					this.request.maxResultCount,
					this.request.skipCount
				)
				.finally(() => {
					this.loading = false;
				})
				.then((res) => {
					this.tableData = res.items;
					this.totalItems = res.totalCount;
					this.tableData.map((item) => {
						item.attDate = item.attDate ? moment(item.attDate).format('YYYY-MM-DD') : ' ';
						item.shouldStartTime = item.shouldStartTime
							? moment(item.shouldStartTime).format('HH:mm')
							: ' ';
						item.shouldEndTime = item.shouldEndTime ? moment(item.shouldEndTime).format('HH:mm') : ' ';
						item.dutyFrom = item.dutyFrom ? moment(item.dutyFrom).format('YYYY-MM-DD  HH:mm') : ' ';
						item.dutyTo = item.dutyTo ? moment(item.dutyTo).format('YYYY-MM-DD  HH:mm') : ' ';
					});
					this.totalPages = Math.ceil(res.totalCount / this.request.maxResultCount);
					this.pagerange = [
						(this.pageNumber - 1) * this.request.maxResultCount + 1,
						this.pageNumber * this.request.maxResultCount,
					];
				});
		},

		/**
		 * 转换表格列内容
		 */
		getInfo(text) {
			let coltext = '';
			this.SignCardNumsList.forEach((item) => {
				if (text == item.itemDetailCode) {
					coltext = item.itemDetailName;
				}
			});
			return coltext;
		},

		/**
		 * 导出为excel
		 */
		exportToExcel() {
			this._HitTimeServiceProxy
				.getEmpattdailyToExcel(
					this.DepartmentId,
					this.CompanyId,
					this.StartTime ? moment(this.StartTime) : undefined,
					this.EndTime ? moment(this.EndTime) : undefined,
					this.emquit,
					this.filterText,
					this.request.sorting,
					this.request.maxResultCount,
					this.request.skipCount
				)
				.then((result) => {
					this._fileDownloadService.downloadTempFile(result);
				});
		},

		/**
		 * 获取周期设置
		 */
		async initCycle() {
			return new Promise((resolve, reject) => {
				this.spinning = true;
				this._enterpriseAttendCycleServiceProxy
					.getByTenant(this.Year, this.Month)
					.finally(() => {
						this.spinning = false;
					})
					.then((res) => {
						resolve(res);
					});
			});
		},
		async getCycle(e) {
			let cycle = await this.initCycle();
			//var newst=moment(cycle.startTime).format('YYYY-MM-DD 00:00:00');
			//var newet=moment(cycle.endTime).format('YYYY-MM-DD 23:59:59');
			this.StartTime = cycle.startTime;
			this.EndTime = cycle.endTime;
			this.DateTime = [this.StartTime, this.EndTime];
			return this.DateTime;
		},

		/**
		 * 勾选事件
		 */
		boxChange() {
			this.request.skipCount = 0;
			this.pageNumber = 1;
			this.getData();
		},

		/**
             /**
             * 分页事件
             */
		showTotalFun() {
			return this.l(
				'GridFooterDisplayText',
				this.pageNumber,
				this.totalPages,
				this.totalItems,
				this.pagerange[0],
				this.pagerange[1]
			);
		},
		/**
		 * 分页
		 */
		onChange(page, pageSize) {
			this.selectedRowKeys = [];
			this.selectedRows = [];
			this.pageNumber = page;
			this.request.skipCount = (page - 1) * this.request.maxResultCount;
			this.getData();
		},
		showSizeChange(current, size) {
			this.selectedRowKeys = [];
			this.selectedRows = [];
			this.pageNumber = 1;
			this.request.maxResultCount = size;
			this.getData();
		},
		/**
		 * 排序
		 * @param pagination
		 * @param filters
		 * @param sorter
		 */
		sorterChange(pagination, filters, sorter) {
			if (sorter.order) {
				this.request.sorting = sorter.columnKey + ' ' + sorter.order.replace('end', '');
			} else {
				this.request.sorting = undefined;
			}
			this.searchChange();
		},
		companyTreeInit() {
			this._CompanyConfigServiceProxy
				.getPaged(undefined, '', 100, 0)
				.finally(() => {
					this.loading = false;
				})
				.then((res) => {
					this.companyList = res.items;
					this.companyList.forEach((item) => {
						if (item.parentId == 0) {
							var obj = {
								key: item.id,
								value: item.id,
								title: item.fullName,
							};
							this.companyTreeList.push(obj);
						}
					});
					this.initCompanyTree(this.companyTreeList);
					// if (this.companyTreeList.length > 0) {
					//     this.companyChange(this.companyTreeList[0].key);
					// }
				});
		},
		/**
		 * 初始化树形结构数据格式
		 */
		initCompanyTree(list) {
			list.forEach((v) => {
				//遍历父级数据，加载子集=
				v.children = []; //创建空的子集
				this.companyList.forEach((m) => {
					if (m.parentId == v.value) {
						//根据主键查子集数据
						var obj = {
							key: m.id,
							value: m.id,
							title: m.fullName,
						};
						v.children.push(obj); //放进父级的集合中
						this.initCompanyTree(v.children); //递归查询子集的集合
					}
				});
				if (v.children.length == 0) {
					//校验如何数据没有子集，删除子集参数
					delete v.children;
				}
			});
		},
		companyChange(value) {
			this.CompanyId = value;
			this.request.skipCount = 0;
			this.pageNumber = 1;
			if (this.CompanyId) {
				this.getDepTreeData();
				this.getData();
			} else {
				this.DepartmentId = undefined;
				this.DepTreeData = [];
			}
		},
		/**
		 * 获取部门
		 */
		getDepTreeData() {
			this.DepartmentId = undefined;
			this.DepTreeData = [];
			this._DepartmentServiceProxy
				.getDepTreeData(this.CompanyId)
				.then((res) => {
					this.DepTree = DepTreeUntils.getInstance().InitSelectData(res);
					this.DepTree.forEach((item) => {
						if (!item.parentId) {
							this.DepTreeData.push(item);
						}
					});
					this.DepTreeData = DepTreeUntils.getInstance().ArrToTree(this.DepTree, this.DepTreeData, false);
				})
				.finally(() => {
					this.loading = false;
				});
		},
		/**
		 * 选择部门
		 */
		SelectDepartmentTree(e) {
			this.DepartmentId = e;
			this.request.skipCount = 0;
			this.pageNumber = 1;
			this.getData();
		},

		onDateChange(e) {
			if (e.length > 0) {
				this.StartTime = e[0];
				this.EndTime = e[1];
				this.monthData = moment(this.EndTime).diff(moment(this.StartTime), 'months');
			}
			this.request.skipCount = 0;
			this.pageNumber = 1;
			this.getData();
		},
	},
};
</script>

<style scoped>
.pagination {
	margin: 10px auto;
	text-align: right;
}
</style>
